import {Component, OnInit, Renderer2} from "@angular/core";
import {ToastrService} from "ngx-toastr";
import {AppService} from "@services/app.service";
import {Router} from "@angular/router";
import {NgxSpinnerService} from "ngx-spinner";
import {Subject, takeUntil} from 'rxjs';
import {UserDetails} from "@/model/user-details/UserDetails";
import SwiperCore, {Navigation, Pagination, SwiperOptions, Scrollbar} from 'swiper';
import {ImageDetails} from "@/model/asset-details/ImageDetails";
import {TraderImageDetails} from "@/model/asset-details/TraderImageDetails";
import {ConfigService} from "@/config.service";
import {tap} from "rxjs/operators";
import {ProductDetails} from "@/model/product/ProductDetails";
import {PostDetails} from "@/model/post/PostDetails";
import {LoggingService} from "@services/logging.service";
import {environment} from "../../../environments/environment";
import {UntypedFormControl, UntypedFormGroup, Validators} from "@angular/forms";
import {BusinessDetails} from "@/model/business/BusinessDetails";
import { FarmerDetails } from "@/model/farmers/farmer-details";

SwiperCore.use([Navigation, Pagination, Scrollbar]);

@Component({
    selector: 'app-verify-profile',
    templateUrl: './verify-profile.component.html',
    styleUrls: ['./verify-profile.component.scss']
})

export class VerifyProfileComponent implements OnInit {
    destroy$: Subject<boolean> = new Subject<boolean>();
    userID: number;
    userDetailsById: UserDetails | undefined;
    userProductsList: ProductDetails[] = [];
    userPostList: PostDetails[] = [];
    regId: number;
    name: string;
    phoneNo: string;
    emailId: string;
    description: string;
    companyName: string;
    aboutBusiness: string;
    registeredAddress: string;
    displayAddress: string;
    paymentStatus: string;
    isTrader: string;
    isActive: string;
    verificationImageList: ImageDetails[] = [];
    traderImageList: TraderImageDetails[] = [];
    businessInformationList: BusinessDetails[] = [];
    farmDetailsList : FarmerDetails [] = [];
    endPoint: string;
    traderDetail: boolean = false;
    isLocalhost = window.location.hostname === 'localhost';
    isErrorShow: string;
    selectedImageUrl: string;
    imageModalShow = false;
    editModalShow: boolean = false;
    isWithoutSubscription: boolean = false;
    isShowSubscription: boolean = false;
    subscriptionType: string = "";
    subscriptionEndDate: number = 0;
    isApproveButtonShow: boolean = false;

    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private configService: ConfigService,
        private loggingService: LoggingService,
    ) {
    }

    userFormGroup = new UntypedFormGroup({
        userRegistrationId: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        name: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        phoneNumber: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        email: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        enrollmentType: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        accountStatus: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        selfDescription: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        createdAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedBy: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
        updatedAt: new UntypedFormControl("", [Validators.required, Validators.nullValidator]),
    });

    OnSubmit() {

    }

    openModal(image: any) {
        console.log(image.imageId);
        this.selectedImageUrl = `${this.endPoint}/api/image-details/get/${image.imageId}`;
        this.imageModalShow = true;
    }

    ngOnInit() {
        this.getUserRegisterDetail();
        this.getUserDetailsList();
        this.endPoint = this.configService.getConfig().END_POINT;
    }

    ngOnDestroy() {
        localStorage.removeItem('userRegId');
    }

    // UserRegId Get User Registered Detail show
    getUserRegisterDetail() {
        this.spinner.show();
        this.userID = Number(localStorage.getItem('userRegId'));
        this.appService
            .getRegisteredDetailsById(this.userID)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    this.userDetailsById = data;
                    if (!environment.production && this.isLocalhost) {
                        console.log("reg details::", this.userDetailsById);
                    }
                    this.regId = this.userDetailsById.userRegistrationId
                    this.name = this.userDetailsById.name;
                    this.phoneNo = this.userDetailsById.phoneNumber;
                    this.emailId = this.userDetailsById.email;
                    this.description = this.userDetailsById.selfDescription;
                    this.isTrader = this.userDetailsById.enrollmentType.toUpperCase();
                    this.isErrorShow = this.userDetailsById.enrollmentType;
                    this.verificationImageList = this.userDetailsById.verificationImageList;
                    this.isActive = this.userDetailsById.accountStatus;
                    this.farmDetailsList = this.userDetailsById.farmDetails;
                    this.businessInformationList = this.userDetailsById.businessInformation;
                    console.log('user business list::', this.businessInformationList);
                    if (this.userDetailsById.traderDetails != null) {
                        this.companyName = this.userDetailsById.traderDetails.companyName;
                        this.aboutBusiness = this.userDetailsById.traderDetails.aboutTheCompany;
                        this.displayAddress = this.userDetailsById.traderDetails.displayAddress;
                        this.registeredAddress = this.userDetailsById.traderDetails.regAddress;
                        this.traderImageList = this.userDetailsById.traderDetails.traderImageList;
                        this.traderDetail = true;
                    } else {
                        this.traderDetail = false;
                    }
                    if (this.userDetailsById.paymentDetails != null) {
                        for (let pay of this.userDetailsById.paymentDetails) {
                            this.paymentStatus = pay.paymentStatus;
                        }
                        if (!environment.production && this.isLocalhost) {
                            console.log('payment::', this.paymentStatus);
                        }
                    }
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                })
    }

    onSelectSubscription(event: Event, subscriptionType: string): void {
        const subscription = (event.target as HTMLInputElement).value;
        console.log(subscription)
        this.isWithoutSubscription = subscription === 'WITHOUT_SUBSCRIPTION';
        this.subscriptionType = subscription;
        console.log(`Selected Subscription Type: ${this.subscriptionType}`);
        if (this.subscriptionType == 'WITHOUT_SUBSCRIPTION'  && this.subscriptionEndDate != 0) {
            this.isApproveButtonShow = true;
        } else if (this.subscriptionType == 'WITH_SUBSCRIPTION') {
            this.isApproveButtonShow = true;
        }
    }

    onSelectSubscriptionEndDate(event: Event): void {
        const endSubscriptionDate = (event.target as HTMLInputElement).value;
        if (endSubscriptionDate) {
            const endSubscriptionEpochInSeconds = Math.floor(new Date(endSubscriptionDate).getTime() / 1000);
            this.subscriptionEndDate = endSubscriptionEpochInSeconds;
            console.log(`Selected Date: ${endSubscriptionDate}`);
            console.log(`Epoch Timestamp: ${endSubscriptionEpochInSeconds}`);
            if (this.subscriptionType && this.subscriptionEndDate != 0) {
                this.isApproveButtonShow = true;
            }
        } else {
        }
    }



    // User Reg Id based User Verification Status update Functionality
    approveStatus(verificationStatus: string, regId: number) {
        const updateVerify: any = {
            verificationStatus,
            registrationId: regId,
            ...(verificationStatus === 'VERIFIED' && {
                subscriptionType: this.subscriptionType,
                subscriptionEndDate: this.subscriptionEndDate,
            }),
        };

        console.log(updateVerify);
        this.appService
            .updateVerificationStatus(updateVerify)
            .pipe(takeUntil(this.destroy$))
            .subscribe((data) => {
                    this.spinner.hide();
                    if (!environment.production && this.isLocalhost) {
                        console.log("update Status::", data);
                    }
                    this.toastr.success('Verification status updated');
                    this.userDetailsById.accountStatus = data.accountStatus;
                    this.getUserRegisterDetail();
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    this.toastr.error(errorMessage);
                });
    }


    // User ID based Products List show functionality
    getUserDetailsList() {
        this.appService.getUserIdBasedDetails(this.userID)
            .pipe((takeUntil(this.destroy$)),
                tap((userListData) => {
                    this.userProductsList = userListData.listOfProducts.sort();
                    this.userProductsList.sort((a, b) => b.productId - a.productId);
                    this.userPostList = userListData.listOfPosts;
                    if (!environment.production && this.isLocalhost) {
                        console.log('User Products::', this.userProductsList);
                        console.log('User Posts::', this.userPostList);
                    }
                    this.userPostList?.sort((a, b) => b.postId - a.postId);
                })
            )
            .subscribe((userDetails) => {
                    if (!environment.production && this.isLocalhost) {
                        console.log('user list::', userDetails)
                    }
                },
                (error: any) => {
                    console.error("Error:", error);
                    this.spinner.hide();

                    const errorMessage = error?.error?.message || "Something went wrong, please try again";
                    if (this.isErrorShow == 'Farmers' ||this.isErrorShow == 'Agri Input'
                        || this.isErrorShow == 'FPO' || this.isErrorShow == 'Food Processors') {
                        console.log(this.isErrorShow)
                        this.toastr.error(errorMessage);
                    }
                })
    }

    //Edit user details
    editUserProfileDetails() {
        this.editModalShow = true;
        this.userFormGroup.controls['name'].setValue(this.name);
        this.userFormGroup.controls['phoneNumber'].setValue(this.phoneNo);
        this.userFormGroup.controls['email'].setValue(this.emailId);
        this.userFormGroup.controls['selfDescription'].setValue(this.description);
    }

    editFarmsDetails(regId : number,userId : number, userType : string){
        localStorage.setItem('farmsId',String(userId));
        localStorage.setItem('userGetType',userType);
        localStorage.setItem('vendorRegId',String(regId));
        this.router.navigate(['/main/business-details']);
    }

    editBusinessDetails(regId : number,userId : number, userType : string){
        localStorage.setItem('businessId',String(userId));
        localStorage.setItem('userGetType',userType);
        localStorage.setItem('vendorRegId',String(regId));
        this.router.navigate(['/main/business-details']);
    }
}